// Data Grid Configuration
/**
 * ROW LAYOUTS describes different types of row sizes
 * @typedef ROW_LAYOUTS
 * @property {string} layoutName
 */
export const ROW_LAYOUTS = ['Roomy', 'Comfortable', 'Compact'];
/**
 * PAGE SIZE describes different types of page size lengths
 * @typedef PAGE_SIZE
 * @property {string} size
 */
export const PAGE_SIZE = ['10', '50', '100', '500', '1000'];

// Work box settings
/**
 * APPLICATION SHELL
 * @typedef APPLICATION_SHELL
 * @property {string} value
 */
export const APPLICATION_SHELL = 'application-shell';
// #region Index Db Databases

/**
 * Application Configurations
 *   @typedef CORE_STORE
 * @property {string} value
 */
export const CORE_STORE = 'Core';

/** Settings */
export const ROW_LAYOUT = 'Core.DataGrid.RowLayout';
export const DEFAULT_LIMIT = 'Core.DataGrid.ItemsPerPage';

/** App Configs */
export const APP_CONFIG_GLOBAL = 'Global App Config';
export const APP_CONFIG = 'App Config';
export const APP_CONFIG_MODIFICATION = 'Modify_App_Config';
export const LISTING_LIMIT = 10;
// #endregion

/**
 * SYNC QUEUE
 * @typedef SYNC_QUEUE
 * @property {string} value
 */
export const SYNC_QUEUE = 'SYNC_Queue';

/**
 * ASSETS IMAGES
 * @typedef ASSETS_IMAGES
 * @property {string} value
 */
export const ASSETS_IMAGES = 'assets-images';

/**
 * Const for lookups
 * Lookup_Selection_Mode used to for single/multiple selection of DataTable item
 * LookUp_Mode used to configure things according to look
 */
export const LookUp_Mode = {
  LookUp: 'LookUp',
  Page: 'page',
};
export const Lookup_Selection_Mode = {
  single: 'Single',
  multiple: 'Multiple',
};

/** GhaphQL_OPERATORS */
/** Export const CONTAINS_OP
 * @constant {string}
 */
export const CONTAINS_OP = 'contains';
/** Export const EQUALS_OP
 * @constant {string}
 */
export const EQUALS_OP = 'eq';
/** Export const NOT_EQUALS_OP
 * @constant {string}
 */
export const NOT_EQUALS_OP = 'neq';
/** Export const GREATER_THAN_OP
 * @constant {string}
 */
export const GREATER_THAN_OP = 'gt';
/** Export const GREATER_THAN_EQUAL_OP
 * @constant {string}
 */
export const GREATER_THAN_EQUAL_OP = 'gte';
/** Export const LESS_THAN_OP
 * @constant {string}
 */
export const LESS_THAN_OP = 'lt';
/** Export const LESS_THAN_EQUAL_OP
 * @constant {string}
 */
export const LESS_THAN_EQUAL_OP = 'lte';
/** Export const Starts_WITH_OP
 * @constant {string}
 */
export const STARTS_WITH_OP = 'startswith';
/** Export const ENDS_WITH_OP
 * @constant {string}
 */
export const ENDS_WITH_OP = 'endswith';

/** Preferences  */
export const PREFERENCES_TILELAYOUT = 'Preferences_TileLayout';

/** Dashboard  */
export const DASHBOARD_TILELAYOUT = 'Dashboard_TileLayout';

/** Authorization */
export const ACCESS_TOKEN = 'Core.AccessToken';
export const ID_TOKEN = 'Core.IdToken';
export const REFRESH_TOKEN = 'Core.RefreshToken';
export const SKIP_SPLASH = 'Core.SkipSplash';
export const AUTH_TOKENS = 'AuthorizationToken';
// Used in UserDetail Context
export const TOKEN_EXP = 'Core.TokenExp';
export const USER_DATA = 'Core.UserData';
export const TENANT_ID = 'Core.TenantId';
export const USER_TENANTS = 'Core.UserTenants';
export const DEFAULT_TENANT = 'Core.DefaultTenant';
/** Export const SYNC_PROCESS_DELAY
 *  @constant
 *  @type {string}
 *  @default
 */

export const SYNC_PROCESS_INTERVAL = {
  /**
   * Save App Config
   */
  APP_CONFIG_MODIFICATION: 120000,
};

/**
 * Workbox Strategy
 */
export const WorkboxStrategy = {
  NETWORKFIRST: 'networkFirst',
  CACHEFIRST: 'cacheFirst',
};

/**
 * Form Controls Render Types
 */
export const RenderTypes = {
  ReadOnly: 'ReadOnly',
  Disabled: 'Disabled',
  Editable: 'Editable',
};

export const TokenExpireMessage = [
  'Security token has expired.',
  'Unable to parse JWT token.',
  'Authorization has been denied for this request.',
  'Authorization has been denied for this request.',
];

/**
 * Display notification settings
 */
export const DisplayNotification = {
  SHOW: 'Show', // show the notification popup and show notification on notification panel
  SILENT: 'Silent', // Do not show the notification popup but notification will be shown in notification panel
  HIDDEN: 'Hidden', //  Do not show the notification popup and notification in notification panel
  ALERT: 'Alert', // Show the notification popup but no notification will be shown in notification panel.
};

/**
 * Start Autest Modules Constants
 */
// user modules
export const USERS = 'Users';
// Autest Load Commands modules
export const LOADCOMMANDS = 'LoadCommands';
// Autest Test Arguments modules
export const TESTARGUMENTS = 'TestArguments';
// Autest Test Case Arguments modules
export const TESTCASEARGUMENTS = 'TestCaseArguments';
// Autest Tests modules
export const TESTS = 'Tests';
// Autest Application Flow modules
export const APPFLOWS = 'ApplicationsAutestAF';
// Autest Application Flow Detail Arguments
export const APPFLOWDTLARG = 'ApplicationsAutestAFDtlArg';
// Autest Application Flow Detail
export const APPFLOWDTL = 'ApplicationsAutestAFDtl';
// Autest Test Steps modules
export const TESTSTEPS = 'TestSteps';
// Autest Test Case modules
export const TESTCASE = 'TestCase';
// Autest Test Case Lookup modules
export const TESTCASELOOKUP = 'TestCaseLookup';
// Autest Remote Server modules
export const REMOTESERVERS = 'RemoteServers';
// Autest Run Set modules
export const RUNSETS = 'RunSets';
// Autest Run Set Arguments modules
export const RUNSETARGUMENTS = 'RunSetArguments';
// Autest Run Set Arguments modules
export const RUNSETDETAILS = 'RunSetDetails';
// Autest Execution modules
export const EXECUTIONS = 'Executions';
// Autest Execution Result modules
export const EXECUTIONRESULTS = 'ExecutionResults';
// Autest Execution Commands modules
export const EXECUTIONCOMMANDS = 'ExecutionCommands';
// Autest Execution Field Entries modules
export const EXECUTIONFIELDENTRIES = 'ExecutionFieldEntries';
// Autest Execution Screenshots modules
export const EXECUTIONSCREENSHOTS = 'ExecutionScreenshots';
// Autest Execution Application Flow modules
export const EXECUTIONAPPLICATIONFLOW = 'ExecutionApplicationFlow';
/**
 * end Autest Modules Constants
 */

/**
 * Start RF++ Modules Constants
 */
// RF Profile Group modules
export const RFPROFILEGROUP = 'RFProfileGroup';
// RF Profile modules
export const RFPROFILE = 'RFProfile';
// RF Profile Setup modules
export const RFPROFILESETUP = 'RFProfileSetup';
// RF Form Info modules
export const RFFORMINFO = 'RFormInfo';
// RF Field Info modules
export const RFFIELDINFO = 'RFFieldInfo';
/**
 * end RF++ Modules Constants
 */

/**
 * Start OrganizationDetail Modules Constants
 */
// OrganizationDetail UserDetail modules
export const ORGANIZATIONUSERS = 'OrganizationUsers';
// OrganizationDetail UnApproved UserDetail modules
export const ORGANIZATIONUNAPPROVEDUSERS = 'OrganizationUnapprovedUsers';
// Application Key modules
export const APPLICATIONKEY = 'ApplicationKey';
/**
 * end OrganizationDetail Modules Constants
 */

// Exception Status Codes
export const EXCEPTION_STATUS_CODES = [99];

// Alert Types
export const ALERT_TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  DAFAULTU: 'default',
};

/**
 * Drawer Configuration
 */
export const DrawerOptions = {
  isCollapseOnSelect: false,
};

/** Export const Autest_SUB_MODULES
 *  @constant
 *  @type {string}
 *  @default
 */
export const Autest_SUB_MODULES = {
  Results: 'Results',
  Analytics: 'Analytics',
};

export const TOGGLE_TEST_ARG_SLIDER = 'TOGGLE_TEST_ARG_SLIDER';
export const TOGGLE_RUN_SET_ARG_SLIDER = 'TOGGLE_RUN_SET_ARG_SLIDER';
export const TOGGLE_RUN_SET_DETAIL_SLIDER = 'TOGGLE_RUN_SET_DETAIL_SLIDER';
export const TOGGLE_EXECUTION_SLIDER = 'TOGGLE_EXECUTION_SLIDER';
export const TOGGLE_PROFILE_SETUP_SLIDER = 'TOGGLE_PROFILE_SETUP_SLIDER';
export const TOGGLE_TEST_STEP_SLIDER = 'TOGGLE_TEST_STEP_SLIDER';
export const TOGGLE_ADD_ORG_USER_SLIDER = 'TOGGLE_ADD_ORG_USER_SLIDER';
export const TOGGLE_APP_KEY_SLIDER = 'TOGGLE_APP_KEY_SLIDER';
export const TOGGLE_APP_FLOW_DTL_ARG_SLIDER = 'TOGGLE_APP_FLOW_DTL_ARG_SLIDER';

/** Export const ROLES  */
export const ROLES = {
  OrganizationAdmin: 'companyadmin',
};

/**
 * Constants for RunSet module
 **/
export const RunSet_LOOKUP = 'RunSetLOOKUP';
export const Location_Hierarchy = 'Location_Hierarchy';
export const Lookup_Hierarchy = 'Lookup_Hierarchy';

/** Export const Autest_SUB_MODULES
 *  @constant
 *  @type {string}
 *  @default
 */
export const AUTH_PERMISSION = {
  RF: 'SMART_RF_PLUS',
  Autest: 'SMART_FEATURE_AUTEST',
  OrganizationAdmin: 'companyadmin',
  OrganizationUser: 'OrganizationUser',
};

/**
 * Constants for Test Github Docs
 **/
export const AUTEST_TEST_GITHUB_RAW_URL =
  'https://raw.githubusercontent.com/OracularIS/autest/main/docs/tests_docs';
export const AUTEST_TEST_GITHUB_REPO_URL =
  'https://github.com/OracularIS/autest/blob/main/docs/tests_docs';

export const TEST_TYPES = ['MOCA', 'RF', 'WEB'];
export const MTF_PROTOCOL = ['TELNET', 'SSH'];
export const RF_FIELD_INFO_TYPES = [
  'cmd',
  'cmd_local',
  'url',
  'pdf',
  'video',
  'img',
];

export const SMART_IS_EMAIL = '@smart-is.com';

/**
 * Index Page Url
 **/
export const INDEX_PAGE = '/products';

// lookupTypes
export const LOOKUP_TYPES = {
  testTypes: 'App.Autest.uc_ossi_test_grp_id',
  groupTypes: 'App.Autest.uc_ossi_grp',
};
